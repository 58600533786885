import React from 'react';
import { cond, propEq } from 'ramda';
import theme from '@greenberry/salal/theme';
import Heading from '@greenberry/salal/components/Heading';
import Text from '@greenberry/salal/components/Text';
import Section from '@greenberry/salal/components/Section';
import Introduction from '@greenberry/salal/components/Introduction';
import HeroV2 from '@greenberry/salal/components/Hero/v2';
import HeroV4 from '@greenberry/salal/components/Hero/v4';
import HeroImage from '@greenberry/salal/components/HeroImage';

import ModularImageHighlightCard from '../../components/ModularImageHighlightCard';
import ModularCopyBlock from '../../components/ModularCopyBlock';

export const renderHero = cond([
  [
    propEq('__typename', 'DatoCmsBasicHero'),
    ({ title }) => (
      <HeroV4
        content={
          <Heading component="h1" size="xxxl" weight="semibold">
            {title}
          </Heading>
        }
      />
    ),
  ],
  [
    propEq('__typename', 'DatoCmsHeroWithImage'),
    ({ title, body, artwork }) => (
      <HeroV2
        content={
          <Section size="2">
            <Heading
              appearance="inherit"
              component="h1"
              size="xxl"
              weight="bold"
            >
              {title}
            </Heading>
            <Text size="m">{body}</Text>
          </Section>
        }
        image={<HeroImage src={artwork.image.url} opacity="1" />}
      />
    ),
  ],
]);

export const renderModularBlock = cond([
  [
    propEq('__typename', 'DatoCmsIntroductionBlock'),
    ({ title, body }) => (
      <Section size="1" style={{ paddingBottom: theme.space('xxl') }}>
        <Introduction title={title} text={body} />
      </Section>
    ),
  ],
  [propEq('__typename', 'DatoCmsCopyBlock'), ModularCopyBlock],
  [
    propEq('__typename', 'DatoCmsImageHighlightCard'),
    ModularImageHighlightCard,
  ],
]);
