import React from 'react';
import PropTypes from 'prop-types';
import Section from '@greenberry/salal/components/Section';
import RichText from '@greenberry/salal/components/RichText';

const ModularCopyBlock = ({ id, body }) => (
  <Section size="1" key={id}>
    <RichText>{body}</RichText>
  </Section>
);

ModularCopyBlock.propTypes = {
  id: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default ModularCopyBlock;
