import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import MainWrapper from '@greenberry/salal/components/MainWrapper';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import { renderHero, renderModularBlock } from './utils';

const ModularPage = ({ data }) => {
  const {
    page: {
      seoMetaTags,
      hero: [hero],
      modularContent,
    },
  } = data;

  return (
    <Layout>
      <SEO tags={seoMetaTags.tags} />
      {renderHero(hero)}
      <MainWrapper>{modularContent.map(renderModularBlock)}</MainWrapper>
    </Layout>
  );
};

ModularPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      seoMetaTags: PropTypes.shape({ tags: PropTypes.array }),
      hero: PropTypes.arrayOf(
        PropTypes.shape({
          __typename: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        })
      ),
      title: PropTypes.string.isRequired,
      modularContent: PropTypes.arrayOf(
        PropTypes.shape({
          __typename: PropTypes.string.isRequired,
        })
      ),
    }),
  }),
};

export const query = graphql`
  query getModularPage($slug: String!) {
    page: datoCmsModulairePage(slug: { eq: $slug }) {
      hero {
        ... on DatoCmsBasicHero {
          __typename
          title
        }
        ... on DatoCmsHeroWithImage {
          __typename
          title
          body
          artwork {
            image {
              url
            }
          }
        }
      }
      title
      seoMetaTags {
        tags
      }
      modularContent {
        ... on DatoCmsIntroductionBlock {
          __typename
          title
          body
        }
        ... on DatoCmsCopyBlock {
          __typename
          id
          body
        }
        ... on DatoCmsImageHighlightCard {
          __typename
          id
          title
          body
          leftAlignImage
          artwork {
            image {
              fluid {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default ModularPage;
