import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Heading from '@greenberry/salal/components/Heading';
import Section from '@greenberry/salal/components/Section';
import ImageHighlightCard from '@greenberry/salal/components/ImageHighlightCard';
import RichText from '@greenberry/salal/components/RichText';

const ModularImageHighlightCard = ({
  id,
  title,
  body,
  artwork,
  leftAlignImage = false,
}) => (
  <Section size="2" key={id}>
    <ImageHighlightCard
      node={<Img fluid={artwork.image.fluid} />}
      orientation={leftAlignImage ? 'rtl' : 'ltr'}
    >
      <Heading appearance="secondary" component="h1" size="xl" weight="bold">
        {title}
      </Heading>
      <RichText>{body}</RichText>
    </ImageHighlightCard>
  </Section>
);

ModularImageHighlightCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  artwork: PropTypes.shape({
    image: PropTypes.shape({ fluid: PropTypes.shape({}).isRequired })
      .isRequired,
  }).isRequired,
  leftAlignImage: PropTypes.bool,
};

export default ModularImageHighlightCard;
